import Message from 'o365.modules.messageBroker.Message.ts';

/**
 * @param {Window|WorkerGlobalScope|ServiceWorker|MessagePort} target - Specifies the target for where to send the message
 * @param {Message} message - Contains the data that should be sent to the provided target
 * @param {Array<Transferable>} transferList - Contains transferable items that should be sent in combination to message
 */
export const sendMessage = async (target: Window|ServiceWorker|MessagePort, message: Message, transferList: Array<Transferable>) => {
    return new Promise((resolve, reject) => {
        const preparedMessage = message.toJSON();

        const messageChannel = new MessageChannel();

        const port1 = messageChannel.port1;
        const port2 = messageChannel.port2;
        
        port1.onmessage = (event) => {
            try {
                const messageJson = event.data;
                const message = Message.fromJSON(messageJson);

                resolve(message);
            } catch (error) {
                reject(error);
            }
        };

        port1.onmessageerror = (event) => {
            reject(event.data);
        };

        target.postMessage(preparedMessage, [port2, ...(transferList ?? [])]);
    });
}

/**
 * @param {Window|WorkerGlobalScope|ServiceWorker|MessagePort} target - Specifies the target for where to listen for messages
 * @param {Map<string, Function>} typeHandlers - Contains a list of message types that should be listened to
 * @param {Function?} defaultHandler - Optional function that can be provided for messages that are not caught by typeHandlers
 */
export const onMessage = (target: Window, typeHandlers: Map<string, Function>, defaultHandler: Function|null) => {
    target.addEventListener('message', (event) => {
        try {
            const messageJson = event.data;
            const message = Message.fromJson(messageJson);

            if (typeHandlers.has(message.type)) {
                typeHandlers.get(message.type)(message, event);
            } else if (defaultHandler) {
                defaultHandler(message, event);
            }
        } catch (error) {
            for (const port of event.ports) {
                try {
                    const errorMessage = new Message('MessageListenerError', error);
                    port.postMessage(errorMessage);
                } catch (error) {
                    // TODO: Should be handled differently
                    console.error(error);
                }
            }
        }
    });
}
